import { differenceInHours, isAfter, isSameDay } from 'date-fns';
import store from 'store';

import type { LearningRecapState } from '../redux/reducers/learningRecap';

import { STORAGE_KEYS } from './useStorage';

/**
 * Prompt the user to see the learn recap modal if they have not seen it before.
 * If they have seen it before, show the learn recap modal. If they have skipped
 * it twice, do nothing.
 */
export function showLearningRecap({
  courseId,
  learningRecap,
  showLearningRecapModal,
  showShowOrSkipRecapModal,
  userEligibleForLearningRecapCallback,
}: {
  courseId?: number;
  learningRecap: LearningRecapState;
  showLearningRecapModal: () => void;
  showShowOrSkipRecapModal: () => void;
  userEligibleForLearningRecapCallback: () => void;
}): void {
  if (courseId == null || learningRecap == null) {
    return;
  }

  const today = new Date();
  const learningRecapDate = learningRecap.lastActiveDate;
  const lastSeenDate = new Date(
    store.get(`${STORAGE_KEYS.LEARNING_RECAP_LAST_SEEN}_${courseId}`) ?? 0,
  );

  const isLastSeenDateGreaterThanLearningRecapDate = isAfter(
    lastSeenDate,
    learningRecapDate,
  );

  const hasSeenLearningRecapToday = isSameDay(today, lastSeenDate);
  if (hasSeenLearningRecapToday || isLastSeenDateGreaterThanLearningRecapDate) {
    return;
  }

  const isLearningRecapFromToday = isSameDay(today, learningRecapDate);
  const elapsedHours = differenceInHours(today, learningRecapDate);

  // Early return if learn recap is from today or if it has been less than 6 hours
  if (isLearningRecapFromToday || elapsedHours < 6) {
    return;
  }

  userEligibleForLearningRecapCallback();

  const userLearningRecapTmpDisabledDate = new Date(
    store.get(STORAGE_KEYS.LEARNING_RECAP_TMP_DISABLED_UNTIL) ?? 0,
  );
  const userHasLearningRecapTmpDisabled = isAfter(
    userLearningRecapTmpDisabledDate,
    today,
  );

  // Update the last seen date to today
  if (!userHasLearningRecapTmpDisabled) {
    store.set(
      `${STORAGE_KEYS.LEARNING_RECAP_LAST_SEEN}_${courseId}`,
      new Date(),
    );
  }

  const userHasEverSeenLearningRecap = store.get(
    STORAGE_KEYS.LEARNING_RECAP_EVER_SEEN,
  );

  if (!userHasEverSeenLearningRecap && !userHasLearningRecapTmpDisabled) {
    showShowOrSkipRecapModal();
  } else if (userHasEverSeenLearningRecap) {
    showLearningRecapModal();
  }
}
