import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import isNaN from 'lodash/isNaN';
import map from 'lodash/map';
import parseInt from 'lodash/parseInt';

export const convertHexToString = (input: any) => {
  const result = attempt(() => {
    const hex = input.match(/[\s\S]{2}/g) || [];
    let output = '';
    for (let i = 0, j = hex.length; i < j; i += 1) {
      output += `%${(String('0') + hex[i]).slice(-2)}`;
    }
    output = decodeURIComponent(output);
    return output;
  });
  return isError(result) ? '' : result;
};

export const replaceHexToString = (text: any) => {
  if (!(text && text.split)) {
    return text;
  }
  return map(text.split('\n'), (line) =>
    line
      // .replace(/(<[0-9a-fA-F]+>)+/g, match =>
      //   convertHexToString(match.replace(/[<>]/g, '')) || match)
      .replace(/<U\+([0-9a-fA-F]+)>+/g, (match: any, hex: any) => {
        const decimal = parseInt(hex, 16);
        const char = String.fromCodePoint(decimal);
        if (isNaN(decimal) || char === '\u0000') {
          return match;
        }
        return char;
      }),
  ).join('\n');
};

export const encodeUnicodeText = (text: string): string =>
  String.fromCharCode(...new TextEncoder().encode(text));
