import type {
  Command,
  Language,
  SessionOutput,
  SessionStatus,
} from '@datacamp/multiplexer-client';
import { wrap } from 'comlink';
import noop from 'lodash/noop';
import raven from 'raven-js';
// eslint-disable-next-line no-restricted-imports
import { Subject } from 'rxjs';
import type { Subscription } from 'rxjs/Subscription';

import config from '../config';
import unreachable from '../interfaces/unreachable';
import type { PyodideInitialisationContext } from '../workers/pyodide.worker';
import PyodideWorker from '../workers/pyodide.worker';

import type { CodeExecutionBackend, Subscriber } from './codeExecutionBackend';
import { encodeUnicodeText } from './unicode';

export type PyodideAPI = {
  cleanupExercise: (exerciseKey: string) => Promise<void>;
  initialisePyodide: (
    context: PyodideInitialisationContext,
    initCommand: Command,
    pyodideDependencies: string[],
  ) => Promise<string>;
  runConsoleCommand: (exerciseKey: string, command: Command) => Promise<string>;
  runSubmitCommand: (exerciseKey: string, command: Command) => Promise<string>;
};

export type PyodideBackend = CodeExecutionBackend & {
  options: {
    language: Language;
    userInfo: unknown;
  };
};

const buildCampusUrl = (path: string): string =>
  config.isProductionDomain()
    ? `https://campus.datacamp.com/${path}`
    : `https://campus.datacamp-staging.com/${path}`;

const blackDepdendencies = [
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/click-8.1.7-py3-none-any.whl',
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/packaging-24.2-py3-none-any.whl',
  buildCampusUrl('whl/mypy_extensions-1.0.0-py3-none-any.whl'),
  buildCampusUrl('whl/pathspec-0.12.1-py3-none-any.whl'),
  buildCampusUrl('whl/platformdirs-4.3.6-py3-none-any.whl'),
];
const pythonwhatDependencies = [
  buildCampusUrl('whl/protowhat-2.2.0-py3-none-any.whl'),
  buildCampusUrl('whl/dill-0.3.9-py3-none-any.whl'),
  buildCampusUrl('whl/markdown2-2.4.13-py2.py3-none-any.whl'),
  buildCampusUrl('whl/black-24.10.0-py3-none-any.whl'),
];
const pyodideBackendDependencies = [
  buildCampusUrl('whl/pythonwhat-2.29.0-py3-none-any.whl'),
];
const pyodideBackendUrl = buildCampusUrl(
  'whl/pyodide_backend-1.6.4-py3-none-any.whl',
);
export const pyodideDependencies = [
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/pyodide_http-0.2.1-py3-none-any.whl',
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/Jinja2-3.1.3-py3-none-any.whl',
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/markupsafe-2.1.5-cp312-cp312-pyodide_2024_0_wasm32.whl',
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/six-1.16.0-py2.py3-none-any.whl',
  'https://cdn.jsdelivr.net/pyodide/v0.27.4/full/asttokens-2.4.1-py2.py3-none-any.whl',
  ...blackDepdendencies,
  ...pythonwhatDependencies,
  ...pyodideBackendDependencies,
  pyodideBackendUrl,
];

export const createPyodideBackend = ({
  userInfo,
}: {
  userInfo: unknown;
}): PyodideBackend => {
  let clientSubscribers: Array<(data: any) => void> = [];
  const sessionStatusSubscribers: Set<Subscriber<SessionStatus>> = new Set();
  const sessionOutputSubscribers: Set<Subscription> = new Set();

  const output$ = new Subject<SessionOutput[]>();

  const worker = new PyodideWorker();
  const pyodideAPI = wrap<PyodideAPI>(worker);
  let busyTasksCounter = 0;
  let exerciseKey: string | null = null;

  const showOutputOrCleanupOldExercise = async (
    resultPromise: Promise<string>,
  ) => {
    if (!exerciseKey) {
      return;
    }

    const currentExerciseKey = exerciseKey;
    const result = await resultPromise;

    if (currentExerciseKey === exerciseKey) {
      if (result != null) {
        output$.next(JSON.parse(result));
      }
    } else {
      pyodideAPI.cleanupExercise(currentExerciseKey);
    }
  };

  const updateStatus = (status: SessionStatus) => {
    sessionStatusSubscribers.forEach((subscriber) => {
      subscriber(status);
    });
  };

  const setBusy = () => {
    if (busyTasksCounter === 0) {
      updateStatus({ status: 'busy' });
    }
    busyTasksCounter += 1;
  };

  const setReady = () => {
    busyTasksCounter = Math.max(0, busyTasksCounter - 1);
    if (busyTasksCounter === 0) {
      updateStatus({ status: 'ready' });
    }
  };

  const runConsoleCommand = async (command: Command) => {
    if (exerciseKey) {
      await showOutputOrCleanupOldExercise(
        pyodideAPI.runConsoleCommand(exerciseKey, command),
      );
    }
  };

  const runSubmitCommand = async (command: Command) => {
    if (exerciseKey) {
      await showOutputOrCleanupOldExercise(
        pyodideAPI.runSubmitCommand(exerciseKey, command),
      );
    }
  };

  const runCommand = (command: Command) => {
    try {
      switch (command.command) {
        case 'console':
          return runConsoleCommand(command);
        case 'submit':
          return runSubmitCommand(command);
        case 'init':
        case 'run':
        case 'soft_init':
        case 'take_solution':
        case 'apply_solution':
        case 'code_completion':
        case 'sendValidationChunk':
        case 'startValidation':
        case 'isValidationDone':
        case 'getValidationResult':
        case 'view':
          throw new Error('not implemented');
        default:
          return unreachable(command.command);
      }
    } catch (error) {
      output$.next([
        {
          type: 'error',
          payload: error.toString(),
        },
      ]);
      raven.captureMessage('Pyodide error occurred while running the code', {
        level: 'warning',
        extra: { error, command },
        stacktrace: true,
      });
    }

    return Promise.resolve();
  };

  return {
    client: {
      on: (event, callback) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers.push(callback);
      },
      off: (event) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers = [];
      },
    },
    output$,
    start: async (_, initCommand) => {
      if (exerciseKey) {
        await pyodideAPI.cleanupExercise(exerciseKey);
      }

      const [, , course, chapter] = window.location.pathname.split('/');
      const exercise = window.location.search.replace(/^.+ex=(\d+).*/, '$1');
      exerciseKey = `${exercise}-${Date.now()}`;

      setBusy();

      try {
        await showOutputOrCleanupOldExercise(
          pyodideAPI.initialisePyodide(
            { course, chapter, exercise, exerciseKey },
            initCommand,
            pyodideDependencies,
          ),
        );
      } catch (error) {
        raven.captureMessage(
          'Pyodide error occurred while initialising the session',
          {
            level: 'warning',
            extra: {
              error,
              encodedError: btoa(encodeUnicodeText(error.toString())),
              initCommand,
            },
            stacktrace: true,
          },
        );
        updateStatus({ status: 'broken' });
        return;
      }

      clientSubscribers.forEach((subscriber) => {
        subscriber({
          body: {
            id: 'pyodide_session',
          },
        });
      });

      setReady();
    },

    input: async (command) => {
      setBusy();
      try {
        await runCommand(command);
      } catch (error) {
        raven.captureMessage('Pyodide error occurred while running the code', {
          level: 'warning',
          extra: { error, command },
          stacktrace: true,
        });
        updateStatus({ status: 'broken' });
        return;
      }
      setReady();
    },
    stop: async () => {
      sessionOutputSubscribers.forEach((subscription) =>
        subscription.unsubscribe(),
      );
      sessionOutputSubscribers.clear();

      sessionStatusSubscribers.clear();
    },
    stopPolling: noop,
    subscribe: (subscriber) => {
      sessionStatusSubscribers.add(subscriber);
      return () => {
        sessionStatusSubscribers.delete(subscriber);
      };
    },
    subscribeToOutputs: (
      predicate,
      listener: (output: SessionOutput) => void,
    ) => {
      const filtered = output$.flatMap((outputs) => {
        return outputs.filter(predicate);
      });

      const subscription = filtered.subscribe(listener);

      sessionOutputSubscribers.add(subscription);

      return subscription;
    },
    options: {
      language: 'python',
      userInfo,
    },
  };
};
