const MAX_RETRIES = 3;

export const fetchWithRetries = async (
  url: string,
  retries = MAX_RETRIES,
  delay = 1000,
): Promise<Response | null> => {
  try {
    const response = await fetch(url, { cache: 'force-cache' });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
      await fetchWithRetries(url, retries - 1);
    }
  }

  return null;
};
