import { z } from 'zod';

export const ActiveCourseImagesResponse = z.discriminatedUnion('type', [
  z.object({
    imageNameToImageTag: z.record(z.string(), z.string()),
    type: z.literal('multiImage'),
  }),
  z.object({
    type: z.literal('notFound'),
  }),
  z.object({
    imageTag: z.string(),
    type: z.literal('singleImage'),
  }),
]);
export type ActiveCourseImagesResponse = z.infer<
  typeof ActiveCourseImagesResponse
>;

export type Images = ActiveCourseImagesResponse;
