import type {
  ISessionOutputSct,
  Language,
  SessionOutput,
} from '@datacamp/multiplexer-client';
import isDeepEqual from 'lodash/isEqual';
import raven from 'raven-js';

import type { State } from '../interfaces/State';

import type { CodeExecutionBackend } from './codeExecutionBackend';
import { createMuxBackend } from './muxBackend';
import prometheusClient from './prometheusClient';
import { createPyodideBackend } from './pyodideBackend';
import { encodeUnicodeText } from './unicode';

let _client: ReturnType<typeof prometheusClient> | undefined;

const client = () => {
  if (_client == null) {
    _client = prometheusClient();
  }
  return _client;
};

const hasOutputDifferentFormattingAcrossDifferentVersions = (
  pyodideOutput: SessionOutput,
  muxOutput: SessionOutput,
): boolean => {
  const commonOutputs = [
    'Signature: ', // Ignore difference outputting function signatures
    'Init signature: ', // Ignore difference outputting function signatures
    'Docstring:', // Ignore difference outputting docstring
    'Help on ', // Ignore difference outputting help information
    '[9.5, 10.75, 11.25, 18.0, 20.0]\nHelp on ', // Ignore specific exercise that includes the above not at the start
    '<function ', // Ignore difference outputting functions
    "<module '", // Ignore difference outputting modules
    '<built-in method ', // Ignore difference printing object's built-in methods
    'poolhouse\n<built-in method ', // Ignore specific exercise that includes the above not at the start
    'Type help', // Ignore difference outputting the help function
    'Average height: 73.6896551724138', // Ignore specific exercise where different versions may show different decimal places
  ];
  const getOutputText = ({ payload }: SessionOutput) =>
    String(
      payload && typeof payload === 'object' ? payload.output : payload,
    ).replace(/0x[0-9a-fA-F]+/g, '0xHEX');

  const pyodideText = getOutputText(pyodideOutput);
  const muxText = getOutputText(muxOutput);

  // When both start the same way but there are irrelevant differences after that
  if (
    commonOutputs.some(
      (text) => pyodideText.startsWith(text) && muxText.startsWith(text),
    )
  ) {
    return true;
  }
  // When different python versions show different outputs for the same command
  if (
    pyodideText.startsWith('Help on ') &&
    (muxText.startsWith('Signature: ') || muxText.startsWith('Docstring:'))
  ) {
    return true;
  }
  if (
    pyodideText.startsWith('<built-in function ') &&
    muxText.startsWith('<function ')
  ) {
    return true;
  }

  // When outputting class types
  const pyodideMatch = /<class '(.+)'>/.exec(pyodideText);
  if (pyodideMatch?.[1] && muxText === pyodideMatch[1]) {
    return true;
  }

  // When the result is the same but the formatting is different
  const removeWhitespace = (text: string) => text.replace(/\s+/g, '');
  if (removeWhitespace(pyodideText) === removeWhitespace(muxText)) {
    return true;
  }

  // When none of the above worked
  return false;
};

const measureTimeToRunOrSubmitCode = ({
  environment,
  time,
}: {
  environment: 'mux' | 'wasm';
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_to_run_or_submit',
    { environment },
    time,
  );
};

const measureTimeUntilSessionReady = ({
  environment,
  exercise,
  time,
}: {
  environment: 'mux' | 'wasm';
  exercise: string;
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_until_session_ready',
    { environment, exercise },
    time,
  );
};

const countPyodideSessionNotReadyWhenRunningCode = () => {
  client().increment(
    'campus__pyodide_session_not_ready_when_running_code',
    {},
    1,
  );
};

const countMuxSessionNotReadyWhenRunningCode = () => {
  client().increment('campus__mux_session_not_ready_when_running_code', {}, 1);
};

const countNumberOfOutputsMismatch = () => {
  client().increment(
    'campus__pyodide_experiment_number_of_outputs_mismatch',
    {},
    1,
  );
};

const countOutputTypeMismatch = () => {
  client().increment('campus__pyodide_experiment_output_type_mismatch', {}, 1);
};

const countOutputValueMismatch = () => {
  client().increment('campus__pyodide_experiment_output_value_mismatch', {}, 1);
};

const countSctCorrectMismatch = () => {
  client().increment('campus__pyodide_experiment_sct_correct_mismatch', {}, 1);
};

const countSctValueMismatch = () => {
  client().increment('campus__pyodide_experiment_sct_value_mismatch', {}, 1);
};

export type DualCodeExecutionBackend = CodeExecutionBackend & {
  options: {
    language: Language;
    userInfo: unknown;
  };
};

// This is a code execution backend combined of the mux + pyodide, but we only show the mux results to the user.
// We send the same commands to the pyodide backend just to measure user impact.
export const dualCodeExecutionBackend = ({
  backendToShow,
  language,
  state,
  userInfo,
}: {
  backendToShow: 'mux' | 'wasm';
  language: Language;
  state: State;
  userInfo: unknown;
}): DualCodeExecutionBackend => {
  const muxSession = createMuxBackend({ language, state, userInfo });
  const pyodideBackend = createPyodideBackend({ userInfo });
  const backend = backendToShow === 'mux' ? muxSession : pyodideBackend;

  const sessionCreatedAt = Date.now();

  let pyodideReady = false;
  let muxReady = false;
  let ignoredPyodide = false;
  let ignoredMux = false;

  let pyodideInputStartedAt: number | null = null;
  let muxInputStartedAt: number | null = null;

  let pyodideOutputHistory: SessionOutput[] = [];
  let muxOutputHistory: SessionOutput[] = [];
  let pyodideSctHistory: SessionOutput[] = [];
  let muxSctHistory: SessionOutput[] = [];
  let fullHistory: Record<string, Array<Record<string, unknown>>> = {};

  const addToFullHistory = (entries: Array<Record<string, unknown>>) => {
    if (entries == null || entries.length === 0) {
      return;
    }
    const timestamp = new Date().toISOString();
    fullHistory[timestamp] = [...(fullHistory[timestamp] ?? []), ...entries];

    // Keep only the last 20 entries to avoid Sentry truncating the message
    if (Object.keys(fullHistory).length > 20) {
      delete fullHistory[Object.keys(fullHistory)[0]];
    }
  };

  const compareScts = () => {
    while (pyodideSctHistory.length > 0 && muxSctHistory.length > 0) {
      const pyodideSct = pyodideSctHistory.shift() as ISessionOutputSct;
      const muxSct = muxSctHistory.shift() as ISessionOutputSct;

      if (pyodideSct.payload.correct !== muxSct.payload.correct) {
        raven.captureMessage('SCT correct mismatch', {
          level: 'warning',
          extra: {
            fullHistory,
            pyodideSct,
            muxSct,
          },
        });
        countSctCorrectMismatch();
        return;
      }

      if (
        pyodideSct.payload.correct &&
        pyodideSct.payload.message !== muxSct.payload.message
      ) {
        raven.captureMessage('SCT value mismatch', {
          level: 'warning',
          extra: {
            fullHistory,
            pyodideSct,
            muxSct,
          },
        });
        countSctValueMismatch();
      }
    }
  };

  const compareOutputs = () => {
    if (pyodideOutputHistory.length === 0 || muxOutputHistory.length === 0) {
      return;
    }

    if (pyodideOutputHistory.length !== muxOutputHistory.length) {
      if (
        muxOutputHistory.length === pyodideOutputHistory.length + 1 &&
        muxOutputHistory[0].type === 'output' &&
        muxOutputHistory[0].payload
          .split('\n')
          .some((line) =>
            line.startsWith(
              'ERROR! Session/line number was not unique in database',
            ),
          )
      ) {
        raven.captureMessage('Session error in mux', {
          level: 'warning',
          extra: {
            pyodideOutputHistory,
            muxOutputHistory,
          },
        });
        muxOutputHistory.shift();
      } else {
        raven.captureMessage('Number of outputs mismatch', {
          level: 'warning',
          extra: {
            fullHistory,
            pyodideOutputHistory,
            muxOutputHistory,
          },
        });
        countNumberOfOutputsMismatch();
        pyodideOutputHistory = [];
        muxOutputHistory = [];
        return;
      }
    }

    while (pyodideOutputHistory.length > 0 && muxOutputHistory.length > 0) {
      const pyodideOutput = pyodideOutputHistory.shift();
      const muxOutput = muxOutputHistory.shift();

      if (pyodideOutput == null || muxOutput == null) {
        // should be unreachable
        return;
      }

      if (pyodideOutput.type !== muxOutput.type) {
        raven.captureMessage('Output type mismatch', {
          level: 'warning',
          extra: {
            fullHistory,
            pyodideOutput,
            muxOutput,
          },
        });
        countOutputTypeMismatch();
        continue; // eslint-disable-line no-continue
      }

      switch (pyodideOutput.type) {
        case 'error': {
          // We don't compare error messages
          break;
        }
        case 'graph': {
          // We don't compare graph outputs
          break;
        }
        case 'sct': {
          if (!pyodideOutput.payload.correct && !muxOutput.payload.correct) {
            // We don't compare SCT errors - if both are incorrect it's ok.
            // This is because error messages are different between pyodide <> mux because of the different python
            // versions.
            // It somewhat decreases the value of the experiment, but it's better than having a lot of false positives.
            // As an alternative, we can check for the content "syntax error" and only ignore those.
            break;
          }
          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: {
                fullHistory,
                pyodideOutput,
                muxOutput,
              },
            });
            countOutputValueMismatch();
          }
          break;
        }
        default: {
          // Ignore some expected differences between the different Python versions, to reduce false positives.
          if (
            hasOutputDifferentFormattingAcrossDifferentVersions(
              pyodideOutput,
              muxOutput,
            )
          ) {
            break;
          }

          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            // Ignore output value mismatch if it's a session error in mux
            if (
              (muxOutput.payload.output ?? '')
                .split('\n')
                .some((line: string) =>
                  line.startsWith(
                    'ERROR! Session/line number was not unique in database',
                  ),
                )
            ) {
              raven.captureMessage('Session error in mux', {
                level: 'warning',
                extra: {
                  pyodideOutputHistory,
                  muxOutputHistory,
                },
              });
              break;
            }

            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: {
                fullHistory,
                pyodideOutput,
                muxOutput,
              },
            });
            countOutputValueMismatch();
          }
          break;
        }
      }
    }
  };

  const addOutputToPyodideHistory = (
    output: SessionOutput | SessionOutput[],
  ) => {
    const outputs = Array.isArray(output) ? [...output] : [output];
    if (outputs.length === 0) {
      outputs.push({ type: 'output', payload: '<no-output>' });
    }
    pyodideOutputHistory.push(...outputs);

    addToFullHistory(
      outputs.map((o) => ({
        source: 'pyodide',
        ...o,
        encodedPayload: btoa(encodeUnicodeText(JSON.stringify(o.payload))),
      })),
    );

    compareOutputs();

    const scts = outputs.filter((o) => o.type === 'sct');
    if (scts.length > 0) {
      pyodideSctHistory.push(...scts);
      compareScts();
    }
  };

  const addOutputToMuxHistory = (output: SessionOutput | SessionOutput[]) => {
    const outputs = Array.isArray(output) ? [...output] : [output];
    if (outputs.length === 0) {
      outputs.push({ type: 'output', payload: '<no-output>' });
    }
    muxOutputHistory.push(...outputs);

    addToFullHistory(
      outputs.map((o) => ({
        source: 'mux',
        ...o,
        encodedPayload: btoa(encodeUnicodeText(JSON.stringify(o.payload))),
      })),
    );

    compareOutputs();

    const scts = outputs.filter((o) => o.type === 'sct');
    if (scts.length > 0) {
      muxSctHistory.push(...scts);
      compareScts();
    }
  };

  muxSession.output$.subscribe((output) => {
    if (!ignoredPyodide) {
      // Only add to history outputs that will be compared. If pyodide
      // was ignored, this output won't be compared.
      addOutputToMuxHistory(output);
    }

    if (muxInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - muxInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'mux',
      time: timeToExecuteCode,
    });

    muxInputStartedAt = null;
  });

  pyodideBackend.output$.subscribe((output) => {
    if (!ignoredMux) {
      // Only add to history outputs that will be compared. If mux
      // was ignored, this output won't be compared.
      addOutputToPyodideHistory(output);
    }

    if (pyodideInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - pyodideInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'wasm',
      time: timeToExecuteCode,
    });

    pyodideInputStartedAt = null;
  });

  muxSession.subscribe(({ status }) => {
    if (status === 'ready' && !muxReady) {
      muxReady = true;

      addToFullHistory([{ mux: 'ready' }]);

      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'mux',
        exercise: window.location.href.replace(/^.+\//g, ''),
        time: timeUntilReady,
      });
    }
  });

  pyodideBackend.subscribe(({ status }) => {
    if (status === 'ready' && !pyodideReady) {
      pyodideReady = true;

      addToFullHistory([{ pyodide: 'ready' }]);

      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'wasm',
        exercise: window.location.href.replace(/^.+\//g, ''),
        time: timeUntilReady,
      });
    }
  });

  return {
    client: {
      off(event) {
        backend.client.off(event);
      },
      on(event, callback) {
        backend.client.on(event, callback);
      },
    },
    input(command) {
      if (muxReady) {
        ignoredMux = false;
        muxInputStartedAt = Date.now();
        muxSession.input(command);
      } else {
        ignoredMux = true;
        countMuxSessionNotReadyWhenRunningCode();
      }

      if (pyodideReady) {
        ignoredPyodide = false;
        pyodideInputStartedAt = Date.now();
        pyodideBackend.input(command);
      } else {
        ignoredPyodide = true;
        countPyodideSessionNotReadyWhenRunningCode();
      }

      if (muxReady && pyodideReady) {
        addToFullHistory([
          {
            source: 'input',
            code: command.code,
            command: command.command,
            sct: command.sct,
          },
        ]);
      }
    },
    options: { language, userInfo },
    // We only show results to the user from the backend to show
    output$: backend.output$,
    start(options, initCommand) {
      muxSession.start(options, initCommand);
      pyodideBackend.start(options, initCommand);

      pyodideOutputHistory = [];
      muxOutputHistory = [];
      pyodideSctHistory = [];
      muxSctHistory = [];
      fullHistory = {};

      addToFullHistory([
        { mux: 'starting', sct: initCommand.sct },
        { pyodide: 'starting', sct: initCommand.sct },
      ]);
    },
    stop() {
      pyodideBackend.stop();
      return muxSession.stop();
    },
    stopPolling() {
      pyodideBackend.stopPolling();
      return muxSession.stopPolling();
    },
    subscribe(subscriber) {
      // We only show readiness status to the user for the backend to show
      return backend.subscribe(subscriber);
    },
    subscribeToOutputs(predicate, listener) {
      // We only show outputs to the user from the backend to show
      return backend.subscribeToOutputs(predicate, listener);
    },
  };
};
